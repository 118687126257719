<template>
  <div>
    <!-- Aquí usamos la prop logoSrc para vincular la imagen -->
    <img ref="logo" class="logo" :src="logoSrc" alt="Logo" />
    <h1 ref="text" class="text">Conectamos tecnología, energía y seguridad</h1>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import anime from 'animejs';

export default {
  // Recibimos la propiedad logoSrc desde el componente padre
  props: {
    logoSrc: {
      type: String,
      required: true, // Aseguramos que logoSrc sea una propiedad obligatoria
    },
  },
  setup() {
    // Definir las referencias a los elementos DOM
    const logo = ref(null);
    const text = ref(null);

    onMounted(() => {

      // Animación para el logo con rebotes verticales
      // Animación para el logo descendiendo lentamente
      anime({
        targets: logo.value,
        translateY: [-1000, 0],  // Desplazarse desde arriba (-200) hasta la posición 0
        opacity: [0, 1],           // Desvanecer el logo de 0 a 1
        boxShadow: [
          { value: '0 0 0px rgba(255, 255, 255, 0)', duration: 0 }, // Sin brillo al principio
          { value: '0 0 20px rgba(255, 255, 255, 0.8)', duration: 1800 }, // Brillo al alcanzar su posición
          { value: '0 0 0px rgba(255, 255, 255, 0)', duration: 1500 }, // Fade del brillo al final
        ],
        duration: 6000,          // Duración de la animación
        easing: 'easeOutExpo',   // Efecto de easing para que la animación sea suave
        delay: 150,              // Retardo antes de que comience la animación
        loop: false,             // No repetir la animación
      });




      // Animación para el texto
      anime({
        targets: text.value,
        translateY: [1000, 0],  // Efecto de subir el texto
        opacity: [0, 1],       // Desvanecer el texto de 0 a 1
        duration: 7000,        // Duración de la animación
        easing: 'easeOutExpo', // Efecto de easing para suavizar la animación
        delay: 400,            // Retardo antes de que comience la animación
      });

      // Añadir el efecto de brillo al logo
      logo.value.style.animation = 'shine 2s infinite ease-in-out';
    });

    return {
      logo,
      text,
    };
  },
};
</script>

<style scoped>
@keyframes shine {
  0% {
    filter: brightness(1);
  }

  50% {
    filter: brightness(1.5);
    /* Aumentar el brillo */
  }

  100% {
    filter: brightness(1);
  }
}

.logo {
  position: relative;
  filter: brightness(1);
  background-size: cover;
  width: 25%;
  height: auto;
}

.text {
  font-size: 2rem;
  font-weight: bold;
  color: #266beb;
  position: relative;
  opacity: 0;
  transform: translateY(100px);
}
</style>
