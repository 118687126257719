<template>
  <div id="app">
    
    <PrincipalView :logoSrc="logoPath" />
  </div>
</template>

<script>
import PrincipalView from './components/PrincipalView.vue';
import logoPath from './assets/logo_rcing.png';
export default {
  name: 'App',
  components: {
    PrincipalView,
  },  
  data() {
    return {
      logoPath, // Se pasa directamente el logo
    };
  },
};
</script>

<style>
#app {
  text-align: center;
  margin-top: 20vh;
}
</style>
